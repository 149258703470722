<template>
  <div>
    <head-layout
      head-title="证书资料"
      :showIcon="false"
      :head-btn-options="headBtnOptions"
      @head-add="headAdd"
      @head-romve="headRomve"
    ></head-layout>

    <grid-layout
      ref="gridLayOut"
      :table-options="tableOption"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
      @gird-handle-select-click="selectionChange"
      >
      <!-- @grid-row-detail-click="detailView" -->
       <template #materialUrl="{row}">
        <el-link type="primary" :href="getFile(row.materialUrl,'url')">{{ getFile(row.materialUrl,'name') }}</el-link>
       </template>
      <template #customBtn="{ row }">
        <el-button
          style="margin: 0 3px"
          type="text"
          size="small"
          @click="rowEdit(row, 'edit')"
          >编辑</el-button
        >
        <el-button
          style="margin: 0 3px"
          type="text"
          size="small"
          @click="rowRemove(row)"
          >删除</el-button
        >
      </template>
    </grid-layout>

    <el-dialog
      title="证书资料"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <avue-form
        :option="formOption"
        ref="addForm"
        v-model="addForm"
        @submit="handleSubmit"
      >
      <template slot="materialUrl">
        <el-upload
            action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
            :headers="{ Authorization: this.$store.getters.token }"
            :on-success="onSuccess"
            accept=".zip,.doc,.docx,.pdf,.jpg,.png"
            :file-list="fileList"
            :on-remove="onRemove"
        >
          <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
                支持扩展名：.zip,.doc,.docx,.pdf,.jpg,.png
            </div>
        </el-upload>
      </template>
      </avue-form>

      <template slot="footer">
        <el-button size="small" @click="handleClose" v-loading="dialogLoading">
          {{ $t(`cip.cmn.btn.celBtn`) }}
        </el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleSave"
          v-loading="dialogLoading"
        >
          {{ $t(`cip.cmn.btn.defBtn`) }}
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";

import { mapGetters } from "vuex";

import * as API from "@/api/equipmentFacilities/equipmentLedger";
export default {
  components: {
    GridLayout,
    HeadLayout,
  },
  computed: {
    ...mapGetters(["userInfo"]),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    headBtnOptions() {
      return this.$route.query.type=='view' ? [] : [
        {
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add",
          type: "button",
          icon: "",
        },
        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "head-romve",
          type: "button",
          icon: "",
        },
      ];
    },

    tableOption() {
      return {
        linklabel: "materialUrl",
        selection: true,
        menu: this.$route.query.type!='view',
        column: [
          {
            label: "证书名称",
            prop: "materialName",
            align: "center",
          },
          {
            label: "证书文件",
            prop: "materialUrl",
            align: "left",
            overHidden: true,
            width: 200,
            slot: true
            // formatter: (row,column,value) => {
            //   return JSON.parse(value).name
            // }
          },
        ],
      };
    },

  },
  data() {
    return {
      tableData: [],
      tableLoading: true,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      equipmentLedgerId: "",
      addForm: {
        materialName: '',
        equipmentId: '',
        materialUrl: ''
      },
      formOption: {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 130,
        column: [
          {
            label: "证书名称",
            prop: "materialName",
            span: 12,
            placeholder: "请输入证书名称",
            rules: [
              {
                required: true,
                message: "请输入证书名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "附件",
            prop: "materialUrl",
            type: "upload",
            formslot: true,
            // rules: [
            //   {
            //     required: true,
            //     message: "请上传附件",
            //     trigger: "change",
            //   },
            // ],
            span: 12,
          },
        ],
      },
      dialogVisible: false,
      dialogLoading: false,
      fileList: [],
    };
  },
  mounted() {},
  methods: {
    init(type, typeId, id) {
      this.formType = type;
      this.typeId = typeId;
      this.equipmentLedgerId = id;
      this.onLoad(this.page, {});
    },

    // 获取列表数据
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      API.eqLedgerCertificatePage({
        current: page.currentPage,
        size: page.pageSize,
        orgId: this.userInfo.dept_id,
        equipmentId: this.equipmentLedgerId,
        ...params,
      }).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      });
    },
    // 选择列表数据
    selectionChange(list) {
      this.selectionList = list;
    },
    // 批量删除
    headRomve() {
      let selectionList = this.$refs.gridLayOut.selectionList;
      if (selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return API.eqLedgerCertificateRemove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    // 单条删除
    rowRemove(row) {
      this.$confirm("确定删除当前数据?", {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      }).then(() => {
        API.eqLedgerCertificateRemove(row.id).then(() => {
          this.$message.success("操作成功！");
          this.onLoad(this.page);
        });
      });
    },
    headAdd() {
      this.addForm={
        orgId: this.userInfo.dept_id,
        equipmentId: this.equipmentLedgerId,
        materialName: '',
        materialUrl: '',
      }
      this.dialogVisible = true
    },

    onSuccess (response, file,files) {
      this.fileList = []
          if(file.response){
            this.fileList = [{
                  name: file.name,
                  url: file.response.data.link
              }]
          } else {
              this.fileList = [{
                  name: file.name,
                  url: file.url
              }]
          }
      this.addForm.materialUrl = this.fileList[0]
    },

    onRemove (file,fileList) {
      this.fileList = this.fileList.filter(val=>val.url!=file.url)
      this.addForm.materialUrl = ''
    },

    handleClose () {
      this.dialogVisible = false
      this.fileList = []
    },

    handleSave () {
      this.$refs.addForm.submit()
    },

    handleSubmit () {
      this.$refs.addForm.validate((valid, done) => {
        if(valid){
          this.addForm.materialUrl = JSON.stringify(this.addForm.materialUrl)
          API.eqLedgerCertificateSubmit(this.addForm).then(res => {
            this.$message.success("操作成功")
            this.page.currentPage = 1
            this.onLoad(this.page)
            this.handleClose()
            done()
          }).catch((err) => { done();})
        }
      })
    },

    getFile (file, key){
      return file ? JSON.parse(file)[key] : ''
    },

    rowEdit (row) {
      this.addForm={
        orgId: row.orgId,
        equipmentId: row.equipmentId,
        materialName: row.materialName,
        materialUrl: row.materialUrl ? JSON.parse(row.materialUrl) : '',
        id: row.id
      }
      this.fileList = this.addForm.materialUrl ? [this.addForm.materialUrl] : []
      this.dialogVisible = true
    }
  },
};
</script>
<style scoped lang="scss"></style>
