var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ledger-form" },
    [
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "left", type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "基本信息", name: "baseInfo" } },
            [
              _c("baseInfo", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeName == "baseInfo",
                    expression: "activeName == 'baseInfo'",
                  },
                ],
                ref: "baseInfo",
                on: { addCallBack: _vm.addCallBack },
              }),
            ],
            1
          ),
          _vm.equipmentLedgerId || _vm.currentId
            ? [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "证书资料", name: "certificateInfo" } },
                  [
                    _c("certificateInfo", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeName == "certificateInfo",
                          expression: "activeName == 'certificateInfo'",
                        },
                      ],
                      ref: "certificateInfo",
                    }),
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "检查记录", name: "checkRecord" } },
                  [
                    _c("checkRecord", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeName == "checkRecord",
                          expression: "activeName == 'checkRecord'",
                        },
                      ],
                      ref: "checkRecord",
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.specialFlag == 1 && (_vm.equipmentLedgerId || _vm.currentId)
            ? [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "检验记录", name: "testRecord" } },
                  [
                    _c("testRecord", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeName == "testRecord",
                          expression: "activeName == 'testRecord'",
                        },
                      ],
                      ref: "testRecord",
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }