<template>
  <el-dialog
    :title="title"
    :modal-append-to-body="false"
    :visible.sync="showDialog"
    width="55%"
    class="qmDialog"
    @close="closeDialog"
  >
    <avue-form
      :option="options"
      ref="addForm"
      v-model="form"
      @submit="treeNodeSave"
    >
      <template slot="documentUrl">
        <el-upload
          :class="{ uoloadSty: showImg, disUoloadSty: noneUploadImgCer }"
          action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
          :headers="headers"
          :on-preview="handlePreview"
          list-type="picture-card"
          :file-list="certificateFilesFileList"
          file="file"
          :on-remove="handleDeleteImgRemoveCer"
          :on-success="handleIdFilesSuccessCer"
          :on-change="uploadImgChangeCer"
          :before-upload="beforeAvatarUpload"
          accept=".jpeg,.jpg,.gif,.png"
          :limit="limitCountImg"
        >
          <i slot="default" class="el-icon-plus"></i>
        </el-upload>
      </template>
    </avue-form>
    <span slot="footer">
      <el-button size="small" @click="closeDialog" v-loading="dialogLoading">
        {{ $t(`cip.cmn.btn.celBtn`) }}
      </el-button>
      <el-button
        v-if="pageType != 'view'"
        size="small"
        type="primary"
        @click="handleTreeNodeSave"
        v-loading="dialogLoading"
      >
        {{ $t(`cip.cmn.btn.defBtn`) }}
      </el-button>
    </span>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </el-dialog>
</template>

<script>
import { getToken } from "@/util/auth";
import {
  eqLedgerTestRecordSubmit,
  eqLedgerTestRecordDetail,
} from "@/api/equipmentFacilities/equipmentLedger";
import { dateFormat } from "@/util/date";
export default {
  data() {
    return {
      showDialog: false,
      dialogLoading: false,
      treeReadOnlyFlag: false,
      readOnly: false,
      form: {},
      propObj: {},
      title: "",
      pageType: "",
      showImg: true, //默认展示
      noneUploadImgCer: false, //
      certificateFilesFileList: [],
      limitCountImg: 1,
      dialogImageUrl: "",
      dialogVisible: false,
    };
  },
  computed: {
    headers: function () {
      return {
        "Sinoma-Auth": getToken(),
      };
    },
    options() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 110,
        column: [
          {
            label: "检验单位",
            prop: "testOrg",
            disabled: this.pageType == "view",
            type: "textarea",
            maxlength: 255,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: "检验单位",
                trigger: "blur",
              },
            ],
          },
          {
            label: "检验日期",
            prop: "testDate",
            type: "date",
            format: "yyyy-MM-dd", // 设置日期格式
            valueFormat: "yyyy-MM-dd", // 设置数据库中存储的日期格式
            disabled: this.pageType == "view",
            rules: [
              {
                required: true,
                message: "请选择检验日期",
                trigger: "change",
              },
            ],
          },
          {
            label: "检验结果",
            prop: "testResult",
            type: "textarea",
            maxlength: 255,
            showWordLimit: true,
            disabled: this.pageType == "view",
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入检验结果",
                trigger: "blur",
              },
            ],
          },
          {
            label: "检验证书",
            prop: "documentUrl",
            slot: true,
            disabled: this.pageType == "view",
            rules: [
              {
                required: false,
                message: "检验单位",
                trigger: "blur",
              },
            ],
          },
        ],
      };
    },
  },
  methods: {
    init(type, data) {
      this.form = {};
      this.showDialog = true;
      this.treeReadOnlyFlag = false;
      this.noneUploadImgCer = false;

      this.pageType = type;
      this.propObj = data;
      if (type == "edit") {
        this.title = "特种设备检验记录编辑";
        this.form.testDate = data.testDate;
          // this.form.testOrg = data.testOrg;
          // this.form.testResult = data.testResult;
          this.form = data
          // this.form.documentUrl = data.documentUr ? JSON.parse(data.documentUrl) : []
          this.certificateFilesFileList = data.documentUrl
            ? JSON.parse(data.documentUrl).map((item) => {
                return {
                  ...item,
                  url: item.link,
                  fileUrl: item.link,
                };
              })
            : [];
            this.noneUploadImgCer = this.certificateFilesFileList.length >= this.limitCountImg;
      } else {
        this.title = "特种设备检验记录新增";
        this.form.testDate = dateFormat(new Date(), "yyyy-MM-dd")
        this.form.equipmentId = this.$route.query.id
        this.form.equipmentName = this.$route.query.name
        this.form.equipmentCode = this.$route.query.code
        this.form.organizationId = this.$route.query.orgId
        this.form.organizationCode = this.$route.query.orgCode
      }
    },
    // 新增左侧树节点
    handleTreeNodeSave() {
      if (this.pageType != "view") {
        this.$refs.addForm.submit();
      }
    },
    treeNodeSave(data) {
      this.$refs.addForm.validate((valid, done) => {
        if (valid) {
          if (this.pageType != "edit") {
            data.id = "";
          }
          let queryData = {
            ...data,

          };
          if (queryData.documentUrl instanceof Array) {
            queryData.documentUrl = JSON.stringify(queryData.documentUrl);
          }
          this.dialogLoading = true;
          this.$loading();
          eqLedgerTestRecordSubmit(queryData)
            .then((res) => {
              const { msg, data } = res.data;
              this.treeReadOnlyFlag = true;
              if (res.data.code == 200) {
                this.$message.success(
                  this.$t("cip.cmn.msg.success.operateSuccess")
                );
              } else {
                this.$message.success(msg);
              }
              this.$emit("callback");
              this.form = {};
              done();
              this.certificateFilesFileList = [];
              this.showDialog = false;
              this.dialogLoading = false;
              this.$loading().close();
            })
            .catch((err) => {
              done();
              this.$loading().close();
            })
            .finally(() => {
              done();
              this.dialogLoading = false;
            });
        }
      });
    },
    closeDialog() {
      this.form = {};
      (this.certificateFilesFileList = []), this.$refs.addForm.resetFields();
      this.showDialog = false;
    },
    //健康证明
    handleIdFilesSuccessCer(res, file, fileList) {
      this.form.documentUrl = fileList.map((item) => {
        if (item.response) {
          return item.response.data;
        } else {
          return item;
        }
      });
    },
    handlePreview(file) {
      //判断只有图片可以点击打开放大图
      const fileName = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (
        fileName == "jpg" ||
        fileName == "png" ||
        fileName == "jpeg" ||
        fileName == "bmp"
      ) {
        this.dialogVisible = true;
        if (file.response) {
          //判断新增和编辑时候 地址不一样
          this.dialogImageUrl = file.response.data.objectUrl
            ? file.response.data.objectUrl
            : file.response.data.link;
        } else {
          this.dialogImageUrl = file.link;
        }
      }
    },
    beforeAvatarUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/gif" ||
        file.type === "image/png";

      if (!isJPG) {
        this.$message.warning("上传图片只能是 自定义文件 格式!");
      }
      if (!isLt20M) {
        this.$message.warning("上传文件大小不能超过 20MB!");
      }
      return isJPG && isLt20M;
    },
    // 判断数量，是否显示上传icon
    uploadImgChangeCer(file, fileList) {
      this.noneUploadImgCer = fileList.length >= this.limitCountImg;
    },
    // 删除图片，判断数量，是否显示icon
    handleDeleteImgRemoveCer(file, fileList) {
      this.form.documentUrl = fileList.map((item) => {
        if (item.response) {
          return item.response.data;
        } else {
          return item;
        }
      });
      this.noneUploadImgCer = fileList.length >= this.limitCountImg;
    },
  },
};
</script>
<style scoped lang="scss">
.disUoloadSty {
  ::v-deep .el-upload--picture-card{
    display: none;
  }
  ::v-deep .el-upload__tip{
    display: none;
  }
  ::v-deep .el-upload--text{
    display: none;
  }
}
</style>
