<template>
  <el-dialog
    :title="'扫描二维码'"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :visible.sync="showDialog"
    width="40%"
    :custom-class="'qrcodeDialog'"
    @close="closeDialog"
  >
    <!-- <div id="qrCode" ref="qrCodeDiv" class="qrcodeDiv"></div> -->
    <div class="qrcodeBox" ref="qrcodeBox">
      <img :src="qrcodeSrc" style="width: 200px;height:200px" alt="">
      <!-- <el-image :src="qrcodeSrc" style="width: 200px;height:200px"></el-image> -->
    </div>

  </el-dialog>
</template>
<script>
import html2canvas from "html2canvas";
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      showDialog: false,
      qrcodeText: '',
      qrcodeSrc:''
    };
  },
  methods: {
    init(qrcodeSrc,qrcodeText) {
      this.showDialog = true;
      this.qrcodeText = qrcodeText
      this.qrcodeSrc = qrcodeSrc
      // this.bindQRCode()
      this.$nextTick(()=>{
        this.downloadImg()
      })

    },
    closeDialog() {
      this.showDialog = false;
    },
    bindQRCode() {
    },
    downloadImg() {
      var link = document.createElement("a");
      link.href = this.qrcodeSrc;
      // 设置下载的文件名
      link.download = this.qrcodeText;
      // 触发链接点击
      link.click();

    }
  },
};
</script>
<style scoped lang="scss">
::v-deep .qrcodeDialog .el-dialog__body{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}
.qrcodeBox{
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.qrcodeDiv{
    width: 200px;
    height: 200px;
}
</style>
